export const SaveAnyEntitySupportMixin = {
  data() {
    return {
      dataUpdate: {},
      saveTimeout: null,
      saveAfterTime: 1500,
      expeditedSaveAfterTime: 250
    };
  },
  methods: {
    addToDataUpdate(key, val, expediteSave = false) {
      if (key.indexOf(".") === -1) {
        this.dataUpdate[key] = val;
      } else {
        // Only 1 level nesting
        const props = key.split(".");
        if (!this.dataUpdate[props[0]]) {
          this.dataUpdate[props[0]] = {};
        }
        this.dataUpdate[props[0]][props[1]] = val;
      }

      if (val && this.$v && this.$v.entity && this.$v.entity[key]) {
        this.$v.entity[key].$reset();
      }

      this.beforeSaveEntity(expediteSave);
    },
    beforeSaveEntity(expediteSave = false) {
      clearTimeout(this.saveTimeout);

      const timeoutTime = expediteSave ? this.expeditedSaveAfterTime : this.saveAfterTime;

      this.saveTimeout = setTimeout(() => {
        if (this.$v) {
          this.$v.$touch();

          if (this.$v.$anyError) {
            return;
          }
        }

        if (typeof this.saveEntity === "function") {
          this.saveEntity();
        }
      }, timeoutTime);
    },
    afterSaveEntity() {
      this.dataUpdate = {};
    }
  }
};
