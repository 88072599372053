<template>
  <div class="main-container">
    <winbox-global-progress v-if="loaderIsVisible" />
    <div class="main-header">
      <div class="logo">
        <router-link :to="{ path: '/' }">
          <img :src="logoImg" width="40" height="40" alt="Winmo" />
        </router-link>
      </div>
      <div v-if="isAuthorized" class="main-header-block">
        <winbox-app-header />
      </div>
      <winbox-global-error-notification />
    </div>
    <div class="main-content-wrap">
      <div v-if="isAuthorized" class="main-menu">
        <winbox-sidebar-menu
          :filterOptions="filterOptions"
          :searchPage="searchPage"
          @open-popup-filter="openPopupFilter"
        />
        <winbox-user-avatar v-if="showAvatar" />
      </div>
      <div
        class="main-content"
        :class="{ 'profile-page': isProfilePage }"
        :style="[isEntityPage ? { marginLeft: 220 + 'px' } : '']"
      >
        <div v-if="isAuthorized" class="breadcrumbs-wrap">
          <winbox-breadcrumbs />
        </div>
        <router-view v-if="isReady" />
      </div>
    </div>
    <div class="main-modal-bg"></div>
    <notifications group="main" />
    <winbox-popover
      :type="selectedFilterType"
      :searchPage="searchPage"
      :selectedFilters="selectedFilters"
      :topPosition="topPosition"
      @add-filter="addFilter"
      @remove-filter="removeFilter"
      @select-all="selectAll"
      @remove-all="removeAll"
      @check-filter-option="checkFilterOption"
      @close-popup-filter="closePopupFilter"
    />
  </div>
</template>

<script>
import logoImg from "@/../assets/images/logo.png";

import WinboxGlobalProgress from "@/components/Atoms/LoadingIndicators/WinboxGlobalProgress";

import WinboxGlobalErrorNotification from "@/components/Molecules/WinboxGlobalErrorNotification";

import WinboxSidebarMenu from "@/components/Molecules/WinboxSidebarMenu";
import WinboxUserAvatar from "@/components/Molecules/WinboxUserAvatar";

import WinboxAppHeader from "@/components/Organisms/WinboxAppHeader";
import WinboxBreadcrumbs from "@/components/Organisms/WinboxBreadcrumbs";
import WinboxPopover from "@/components/Organisms/WinboxPopover";

import * as AuthApi from "@/api/auth/auth";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { MutationTypes } from "@/constants/MutationTypes";
import vueCookie from "vue-cookie";

export default {
  name: "App",
  components: {
    WinboxGlobalProgress,
    WinboxGlobalErrorNotification,
    WinboxSidebarMenu,
    WinboxUserAvatar,
    WinboxAppHeader,
    WinboxBreadcrumbs,
    WinboxPopover,
  },
  data() {
    return {
      isReady: false,
      logoImg: logoImg,
      showAvatar: false,
      selectedFilters: [],
      selectedFilterType: '',
      searchPage: '',
      topPosition: 0,
    };
  },
  beforeCreate() {
    const existingSession = vueCookie.get("session");

    if (existingSession && existingSession.length) {
      const session = JSON.parse(existingSession);

      this.$store.commit(MutationTypes.SET_USER, session.user);
      this.$store.commit(MutationTypes.SET_AUTH, session.tokens);
    }
  },
  mounted() {
    const routePath = this.$route.path;
    const path = this.$router.currentRoute.path.substr(1);
    if(['companies', 'brands', 'agencies', 'contacts'].includes(path)) {
      let { locations, jobs, ranks } = this.$route.query;
      if(path === 'companies') {
        this.$store.dispatch("getCompanyFilterOptions");
      }
      else if(path === 'brands') {
        this.$store.dispatch("getBrandFilterOptions");
      }
      else if(path === 'agencies') {
        this.$store.dispatch("getAgencyFilterOptions");
      }
      else if(path === 'contacts') {
        this.$store.dispatch("getContactFilterOptions");
      }

      if(locations != undefined && locations != '') {
        this.$store.dispatch(`getLocationFilterOptions`);
        locations?.split(',').forEach((location) => {
          let filterOptionItem = this.filterOptionItems.find((filterOptionItem) => filterOptionItem.code === location);
          if(filterOptionItem) {
            this.selectedFilters = [...this.selectedFilters, {type: 'locations', item: filterOptionItem.code }];
          }
        });

        this.checkFilterOption('locations', true);
      }

      if(jobs != undefined && jobs != '') {
        this.$store.dispatch("getJobFunctionFilterOptions").then(() => {
          jobs?.split(',').forEach((jobFunction) => {
            let filterOptionItem = this.filterOptionItems.find((filterOptionItem) => filterOptionItem.code === parseInt(jobFunction));
            if(filterOptionItem) {
              this.selectedFilters = [...this.selectedFilters, {type: 'jobs', item: filterOptionItem.code }];
            }
          });

          this.checkFilterOption('jobs', true);
        });
      }

      if(ranks != undefined && ranks != '') {
        this.$store.dispatch("getTitleRankFilterOptions").then(() => {
          ranks?.split(',').forEach((titleRank) => {
            let filterOptionItem = this.filterOptionItems.find((filterOptionItem) => filterOptionItem.code === parseInt(titleRank));
            if(filterOptionItem) {
              this.selectedFilters = [...this.selectedFilters, {type: 'ranks', item: filterOptionItem.code }];
            }
          });

          this.checkFilterOption('ranks', true);
        });
      }

      this.searchPage = `${path[0].toUpperCase()}${path.substr(1).toLowerCase()}`;
    }
    else {
      this.searchPage = '';
    }

    if (
      routePath !== WinboxRoutePaths.LOGIN &&
      routePath !== WinboxRoutePaths.LOGOUT &&
      routePath !== WinboxRoutePaths.RESET_PASSWORD
    ) {
      this.getData().then(() => {
        this.isReady = true;
      });
    } else {
      this.isReady = true;
    }
  },
  watch: {
    '$route': {
      handler (oldUrl, newUrl) {
        const path = this.$router.currentRoute.path.substr(1);
        if(['companies', 'brands', 'agencies', 'contacts'].includes(path)) {
          let { locations } = this.$route.query;
          if(path === 'companies') {
            this.$store.dispatch("getCompanyFilterOptions");
          }
          else if(path === 'brands') {
            this.$store.dispatch("getBrandFilterOptions");
          }
          else if(path === 'agencies') {
            this.$store.dispatch("getAgencyFilterOptions");
          }
          else if(path === 'contacts') {
            this.$store.dispatch("getContactFilterOptions");
          }

          if(oldUrl.path != newUrl.path) {
            this.selectedFilters = [];
            this.filterOptions.forEach((filterOption, index) => {
              let newItem = this.filterOptions[index];
              this.$set(this.filterOptions, index, {name: newItem.name, id: newItem.id, code: newItem.code});
            })
          }

          this.searchPage = `${path[0].toUpperCase()}${path.substr(1).toLowerCase()}`;
        }
        else {
          this.searchPage = '';
        }
      }
    }
  },
  computed: {
    loaderIsVisible() {
      return this.$store.getters.GLOBAL_LOADER_VISIBLE;
    },
    user() {
      return this.$store.getters.USER;
    },
    isAuthorized() {
      return this.user && this.user.id;
    },
    isProfilePage() {
      return this.$store.getters.IS_PROFILE_PAGE;
    },
    isEntityPage() {
      return !this.$store.getters.IS_PROFILE_PAGE && ['Companies', 'Brands', 'Agencies', 'Contacts'].includes(this.searchPage);
    },
    filterOptions() {
      return this.$store.getters.FILTER_OPTIONS;
    },
    filterOptionItems() {
      return this.$store.getters.FILTER_OPTION_ITEMS;
    },
  },
  methods: {
    addFilter({type, item}) {
      this.selectedFilters = [...this.selectedFilters, {type, item}];
      this.updateResults();
    },
    removeFilter(item) {
      let index = this.selectedFilters.map((selectedFilter) => selectedFilter.item).indexOf(item);
      this.selectedFilters.splice(index, 1);
      this.updateResults();
    },
    selectAll(type) {
      let arr = this.filterOptionItems.map((filterOptionItem) => ({
        item: filterOptionItem.code,
        type,
      }));

      let remainingFilters = this.selectedFilters.filter((selectedFilter) => selectedFilter.type != type);

      this.selectedFilters = [...remainingFilters, ...arr];
      this.checkFilterOption(type, true);
      this.updateResults();
    },
    removeAll(type) {
      let remainingFilters = this.selectedFilters.filter((selectedFilter) => selectedFilter.type != type);

      this.selectedFilters = [...remainingFilters];
      this.checkFilterOption(type, false);
      this.updateResults();
    },
    openPopupFilter(name, topPosition) {
      this.selectedFilterType = name;
      this.topPosition = topPosition;
    },
    closePopupFilter() {
      this.topPosition = 0;
    },
    checkFilterOption(type, isChecked) {
      let index = this.filterOptions.map((filterOption) => filterOption.code).indexOf(type);
      if(index >= 0) {
        this.$set(this.filterOptions, index, {...this.filterOptions[index], checked: isChecked});
      }
    },
    getData() {
      return Promise.all([this.verifyResetRoute()]);
    },
    verifyResetRoute() {
      if (!this.$router.currentRoute.fullPath.includes("/reset")) {
        this.getAuth();
      }
    },
    getUsers() {
      return this.$store.dispatch("getUsers");
    },
    getAuth() {
      return AuthApi.getCurrentAccount()
        .then(data => {
          this.$store.commit(MutationTypes.SET_USER, data);
        })
        .catch(e => {
            this.$router.push(WinboxRoutePaths.LOGIN);
        });
    },
    updateResults() {
      const path = this.$router.currentRoute.path.substr(1);
      if(path === 'brands') {
        if(this.selectedFilters.length > 0) {
          this.$router.push({ path: 'brands', query: { locations: this.selectedFilters.map((filter) => filter.item).join(',') }})
        }
        else {
          this.$router.push({ path: 'brands' })
        }
      }
      else if(path === 'companies') {
        if(this.selectedFilters.length > 0) {
          this.$router.push({ path: 'companies', query: { locations: this.selectedFilters.map((filter) => filter.item).join(',') }})
        }
        else {
          this.$router.push({ path: 'companies' })
        }
      }
      else if(path === 'agencies') {
        if(this.selectedFilters.length > 0) {
          this.$router.push({ path: 'agencies', query: { locations: this.selectedFilters.map((filter) => filter.item).join(',') }})
        }
        else {
          this.$router.push({ path: 'agencies' })
        }
      }
      else if(path === 'contacts') {
        let selectedFilterTypes = this.selectedFilters.map((filter) => filter.type).filter(this.getDistinctItems);
        if(selectedFilterTypes.length > 0) {
          let query = {};

          selectedFilterTypes.forEach((selectedFilterType) => {
            let obj = this.formQuery(selectedFilterType);
            query = {...query, ...obj};
          });
          this.$router.push({
            path,
            query,
          });
        }
        else {
          this.$router.push({ path });
        }
      }
    },
    getDistinctItems(value, index, self) {
      return self.indexOf(value) === index;
    },
    formQuery(queryName) {
      let queryItems = this.selectedFilters.filter((filter) => filter.type === queryName).map((filter) => filter.item).join(',');
      if(queryItems.length > 0) {
        return { [queryName]: queryItems};
      }
      return;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.main-container {
  min-width: $winbox-min-site-width;
}
.main-header {
  background: $winbox-primary-bg-color;
  color: #fff;
  padding: 10px 0;
  height: $winbox-header-height;
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 3;

  .logo {
    width: $winbox-nav-width;
    text-align: center;
    position: fixed;
  }
  .main-header-block {
    flex-grow: 1;
    padding: 0 6px 0 0;
    margin-left: $winbox-nav-width;
  }
}
.main-content-wrap {
  min-height: $winbox-main-content-min-height;
  display: flex;

  .main-menu {
    color: #fff;
    background: $winbox-nav-bg-color;
    width: $winbox-nav-width;
    min-width: $winbox-nav-width;
    display: flex;
    flex-direction: column;

    > div {
      &:first-child {
        flex-grow: 1;
      }
    }
  }
  .main-content {
    padding: $winbox-main-content-padding;
    flex-grow: 1;
    padding-top: 84px;

    &.profile-page {
      background: $winbox-sidebar-active-bg-color;

      ::v-deep {
        .breadcrumb {
          li.is-active {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.main-modal-bg {
  &.active {
    position: fixed;
    background: rgba(10, 10, 10, 0.4);
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
