var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c("div", { class: ["notification-bar", _vm.type] }, [
        _c(
          "span",
          {
            staticClass: "dismiss-button",
            on: { click: _vm.clearNotification }
          },
          [_vm._v("x")]
        ),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }