var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-reports" },
    [
      _c("winbox-company-header", {
        staticClass: "main-title",
        attrs: { title: "Reports" }
      }),
      _vm._v(" "),
      _c("winbox-data-table", {
        staticClass: "main-data-table",
        attrs: {
          data: _vm.dataset,
          columns: _vm.columns,
          options: _vm.tableOptions,
          slots: _vm.specificSlots
        },
        scopedSlots: _vm._u(
          [
            {
              key: _vm.tableProps.display_name,
              fn: function(data) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(data.row[_vm.tableProps.display_name]) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: _vm.tableProps.last_run,
              fn: function(data) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(data.row[_vm.tableProps.last_run]) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: _vm.tableProps.date_range,
              fn: function(data) {
                return [
                  data.row.takes_dates
                    ? _c("winbox-input", {
                        attrs: {
                          placeholder: "Input a date range to run a report",
                          "date-picker": true,
                          "date-range": true,
                          "max-date": _vm.today,
                          orientation: "top"
                        },
                        model: {
                          value: data.row[_vm.tableProps.date_range],
                          callback: function($$v) {
                            _vm.$set(data.row, _vm.tableProps.date_range, $$v)
                          },
                          expression: "data.row[tableProps.date_range]"
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: _vm.tableProps.run,
              fn: function(data) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "button button-edit",
                      on: {
                        click: function($event) {
                          return _vm.createReport(data.row)
                        }
                      }
                    },
                    [
                      _c("winbox-icon", {
                        attrs: {
                          icon: "download",
                          color: _vm.Colors.system.primaryLight
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }